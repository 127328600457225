.MuiIconButton-root {
  border-radius: 4px !important; }

.MuiIconButton-sizeSmall {
  width: 32px;
  height: 32px; }

.MuiIconButton-sizeSmall .MuiSvgIcon-root {
  width: 20px;
  height: 20px; }

.MuiIconButton-sizeMedium {
  width: 36px;
  height: 36px; }

.MuiIconButton-sizeMedium .MuiSvgIcon-root {
  width: 24px;
  height: 24px; }

.MuiIconButton-sizeLarge {
  width: 48px;
  height: 48px; }

.MuiIconButton-sizeLarge .MuiSvgIcon-root {
  width: 36px;
  height: 36px; }

.MuiTooltip-tooltipPlacementTop {
  bottom: -5px; }

.MuiTooltip-tooltipPlacementRight {
  left: -5px; }

.MuiTooltip-tooltipPlacementBottom {
  top: -5px; }

.MuiTooltip-tooltipPlacementLeft {
  right: -5px; }

.orbit-inline-edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  cursor: pointer; }
  .orbit-inline-edit.disabled {
    cursor: default;
    pointer-events: none; }
    .orbit-inline-edit.disabled .adornments {
      cursor: default; }
  .orbit-inline-edit.inline-text {
    transition: border-bottom-right-radius 0.25s ease-in, border-color 0.25s ease-in; }
    .orbit-inline-edit.inline-text.corner {
      border-bottom-right-radius: 0; }
  .orbit-inline-edit .MuiTextField-root {
    width: 100%;
    padding: 4px 8px; }
  .orbit-inline-edit .adornments {
    display: flex;
    align-items: center;
    padding-left: 8px;
    cursor: pointer; }
    .orbit-inline-edit .adornments p {
      padding-right: 8px; }
    .orbit-inline-edit .adornments .units {
      width: 32px;
      max-width: 32px;
      overflow: hidden; }
  .orbit-inline-edit .inline-text {
    width: 100%;
    padding-left: 8px;
    overflow: hidden; }
    .orbit-inline-edit .inline-text .MuiTypography-root {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    .orbit-inline-edit .inline-text.align-right .MuiTypography-root {
      text-align: end; }
  .orbit-inline-edit .inline-adornments {
    display: flex;
    align-items: center; }
  .orbit-inline-edit .hover-button {
    cursor: pointer;
    visibility: hidden; }
  .orbit-inline-edit:hover .hover-button {
    visibility: visible; }
  .orbit-inline-edit:hover.disabled .hover-button {
    visibility: hidden;
    cursor: default; }
  .orbit-inline-edit .no-hover:hover {
    background-color: transparent !important; }
  .orbit-inline-edit .hide {
    display: none; }

.orbit-inline-select-container {
  position: relative; }
  .orbit-inline-select-container.disabled {
    pointer-events: none; }
  .orbit-inline-select-container:hover {
    cursor: pointer; }
    .orbit-inline-select-container:hover .disabled {
      cursor: default; }
  .orbit-inline-select-container .inline-select-options-paper {
    position: absolute;
    width: 100%;
    z-index: 1;
    transform-origin: top left;
    transform: scaleY(0);
    opacity: 0;
    transition: transform 0.25s ease, opacity 0.25s ease; }
    .orbit-inline-select-container .inline-select-options-paper .inline-select-options-list {
      overflow: auto;
      padding: 0; }
    .orbit-inline-select-container .inline-select-options-paper.open-list {
      transform: scaleY(1);
      opacity: 1; }

.orbit-inline-tooltip {
  background-color: black !important; }
  .orbit-inline-tooltip .tooltip-arrow {
    color: black !important; }

.alert-border {
  animation: blink 0.25s;
  animation-iteration-count: 2; }

.border-fade {
  transition: border-color 0.5s ease-in; }

.border-fade:hover {
  border-color: initial; }

@keyframes blink {
  50% {
    border-color: transparent; } }

.ellipsis-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

li.ellipsis-overflow {
  display: block; }

.bottom-action-adornments-container {
  position: relative; }
  .bottom-action-adornments-container .bottom-action-adornments {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 32px;
    padding-left: 4px;
    transform-origin: top left;
    transform: scaleY(0);
    opacity: 0;
    transition: transform 0.25s ease, opacity 0.25s ease;
    position: absolute;
    z-index: 1;
    right: 0; }
    .bottom-action-adornments-container .bottom-action-adornments .show {
      background-color: #eeeeee; }
    .bottom-action-adornments-container .bottom-action-adornments .adornments {
      display: flex;
      align-items: center;
      border-left: 2px solid;
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-bottom-left-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      border-width: 2px; }
    .bottom-action-adornments-container .bottom-action-adornments .no-hover:hover {
      background-color: transparent !important; }
    .bottom-action-adornments-container .bottom-action-adornments.open-controls {
      transform: scaleY(1);
      opacity: 1; }

.select-adornment {
  height: 32px;
  transform-origin: top right;
  transform: scaleX(0);
  width: 0px;
  opacity: 0;
  transition: transform 0.25s ease, opacity 1s ease, width 0.25s ease;
  padding-right: 8px; }
  .select-adornment.show-control {
    transform: scaleX(1);
    opacity: 1;
    width: 32px; }

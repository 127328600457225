//
// Copyright 2018 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@use 'sass:color';
@use '@material/theme/theme-color';

$fill-color: color.mix(
  theme-color.prop-value(on-surface),
  theme-color.prop-value(surface),
  80%
) !default;
$label-ink-color: rgba(
  theme-color.prop-value(surface),
  theme-color.text-emphasis(high)
) !default;
$action-ink-color: #bb86fc !default;
$dismiss-ink-color: rgba(
  theme-color.prop-value(surface),
  theme-color.text-emphasis(high)
) !default;

$label-type-scale: body2 !default;
$dismiss-icon-size: 18px !default;
$dismiss-button-size: 36px !default;
$min-width: 344px !default;
$max-width: 672px !default;
$mobile-breakpoint: 480px !default;
$viewport-margin-narrow: 8px !default;
$viewport-margin-wide: 24px !default;
$padding: 8px !default;

$elevation: 6 !default;
$shape-radius: small !default; // Key from shape.$category-keywords or CSS length value (e.g., 4px)
$z-index: 8 !default; // One above mdc-dialog

// These variables need to be kept in sync with the values in constants.js.
$enter-duration: 150ms !default;
$exit-duration: 75ms !default;
